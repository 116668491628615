<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn x-large="" text v-on="on" dark>
        <v-icon class="hidden-sm-and-down">mdi-translate</v-icon>
        <h3 class="mx-4">
          {{ currentLocale }}
        </h3>
        <v-icon class="hidden-sm-and-down">keyboard_arrow_down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="changeLocale('ar')">
        <v-list-item-title> عربي </v-list-item-title>
      </v-list-item>
      <v-list-item @click="changeLocale('en')">
        <v-list-item-title> English </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
  }, 
  methods: {
    changeLocale(locale) {
      this.$root.$i18n.locale = locale;
      localStorage.setItem('lang', locale);
      this.$vuetify.rtl = locale === "ar";
      this.$router.go();
      // this.$forceUpdate();
      // this.$root.$forceUpdate();
    },
  },
};
</script>
